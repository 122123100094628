import gql from 'graphql-tag';

export const GET_LOYAL = gql`
  query {
    loyalty {
      loyaltyProgramQuery {
        getProgram {
          loyaltyCurrencyName {
            plural
            mod1
            mod24
            mod50
          }
        }
      }
    }
  }
`;

export const GET_TICKETS = gql`
  query {
    ticket {
      getOwnList(filter: { status: SOLD }, paging: { limit: 1000 }) {
        list {
          id
          status
          order {
            id
          }
          orderItem {
            id
            refundUntil
          }
          place {
            number
            row {
              number
              sector {
                title
              }
            }
          }
          event {
            startTime
            id
            endTime
            team1 {
              title
            }
            team2 {
              title
              logo {
                publicLink
              }
            }
            stage {
              tournament {
                title
                logo {
                  publicLink
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_SEASON_TICKETS = gql`
  query {
    seasonTicket {
      getOwnList(filter: { statuses: [SOLD] }) {
        list {
          id
          order {
            id
          }
          descriptor {
            id
            title
            gracePeriod {
              end
            }
          }
          place {
            number
            row {
              number
              sector {
                title
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PROFILE = gql`
  query {
    users {
      getProfile {
        id
        visibleId
        login
        person {
          name
          surname
          patronymic
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;
export const GET_SEASON_TICKET_PKPASS = gql`
  query($id: ID!) {
    seasonTicket {
      getPkpassById(id: $id) {
        publicLink
      }
    }
  }
`;

export const GET_TICKET_PKPASS = gql`
  query($id: ID!) {
    ticket {
      getPkpassById(id: $id) {
        publicLink
      }
    }
  }
`;
export const GET_CART = gql`
  query {
    order {
      getCurrent {
        items {
          id
        }
      }
    }
  }
`;
export const GET_LOYALTY_STATUS = gql`
  query {
    loyalty {
      loyaltyDescriptorQuery {
        getDescriptor {
          isActive
          loyaltyCurrencyName {
            plural
            mod1
            mod24
            mod50
          }
          bonusActivePeriodDays
          bonusPriceCoverPercentage
          accrualPercentage
          maxExpenditurePerTransactionPercentage
          applicableWithDiscount
          legalityDocumentation {
            publicLink
          }
        }
      }
    }
  }
`;
export const GET_REFERRAL_STATUS = gql`
  query {
    referralDescriptor {
      getActive {
        isActive
      }
    }
  }
`;
export const GET_COINS = gql`
  query {
    loyalty {
      transactionQuery {
        getUserBonusCount
      }
    }
  }
`;
